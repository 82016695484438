<template>
  <div>

    <b-form class="pt-3" @submit="onSubmit" inline>
      <b-container fluid id="mobileSearch">
        <b-row class="bg-white">
          <b-col class="pl-0 p-2">
            <div class="form-group">
              <input class="form-control w-100"
                     id="auftragnr"
                     type="number"
                     v-model="form.auftragnr"
                     placeholder="Auftragsnummer">
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-2">

          </b-col>
        </b-row>
        <b-row class="bg-white">
          <b-col class="pl-0 p-2">
            <div class="form-group">
              <input class="form-control w-100"
                     id="fonds"
                     type="text"
                     v-model="form.searchcombinedname"
                     placeholder="Fonds">
            </div>
          </b-col>
          <b-col class="text-right p-2 col-4">
            <div>
              <b-button
                  variant="primary"
                  class="btn btn-default btn-form"
                  type="submit">Suchen</b-button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="p-0">
            <b-container fluid v-show="anyFilter">
              <b-row class="pt-1 badge-row">
                <b-col md="12" class="text-left">
                  <b-badge variant="dark" class="mx-1" v-if="form.auftragnr">
                    {{form.auftragnr}} <a class="badge-cancel ml-2" @click="resetFilter('auftragnr')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.searchcombinedname">
                    {{form.searchcombinedname}} <a class="badge-cancel ml-2" @click="resetFilter('searchcombinedname')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.auftragsart">
                    {{ mapAuftragsart() }} <a class="badge-cancel ml-2" @click="resetFilter('auftragsart')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.vermittelbar">
                    {{ mapVermittelbar() }} <a class="badge-cancel ml-2" @click="resetFilter('vermittelbar')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.ablauf">
                    {{ mapAblauf() }} <a class="badge-cancel ml-2" @click="resetFilter('ablauf')"><i class="fas fa-times"></i></a>
                  </b-badge>
                  <b-badge variant="dark" class="mx-1" v-if="form.auftragstatus">
                    {{ mapAuftragstatus() }} <a class="badge-cancel ml-2" @click="resetFilter('auftragstatus')"><i class="fas fa-times"></i></a>
                  </b-badge>
                </b-col>
              </b-row>
            </b-container>
          </b-col>
        </b-row>
      </b-container>
    </b-form>

    <div class="row py-3">
      <div class="col-sm-12">
        <div v-if="hasAuftragslistError()">
          <div class="alert alert-danger" role="alert">
            <p>Es ist ein Fehler aufgetreten.</p>
            <p class="fs-7">{{ auftragslistError() }}</p>
          </div>
        </div>

        <b-col class="text-left pl-0 pr-0">

          <b-collapse v-model="sortFilterBar">
            <b-button class="sort-tile mb-3" @click="toggleSortLayer">
              <i class="fa fa-sort-alt"></i> Sortieren<br />
              <span class="currentSort">
                {{ getSortObject( form.sortproperty ).displayName }}
                <i class="fas fa-long-arrow-alt-down" v-if="currentSortDirIsDesc()"></i>
                <i class="fas fa-long-arrow-alt-up" v-else></i>
                <!-- a class="ml-2" @click="resetSort()"><i class="fas fa-times"></i></a -->
              </span>
            </b-button>

            <b-button class="sort-tile mb-3" @click="toggleFilterLayer">
              <i class="fa fa-filter"></i> Filter<br />
              <span class="currentSort">({{ auftragListCount }} Ergebnisse)</span>
            </b-button>
          </b-collapse>

          <b-collapse v-model="filterToggle" id="extended-search" class="pt-3 pl-0 pr-0">
            <b-row class="ml-0 mr-0">
              <b-col md="3" class="mb-2 pl-0">
                <div class="form-group w-100">
                  <b-form-select
                      id="auftragsartselect"
                      size="sm"
                      class="w-100"
                      v-model="form.auftragsart"
                      :options="auftragartoptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Auftragsart --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group w-100">
                  <b-form-select
                      id="vermittelbarselect"
                      size="sm"
                      class="w-100"
                      v-model="form.vermittelbar"
                      :options="vermittelbaroptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Vermittelbarkeit --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
              <b-col md="3">
                <div class="form-group w-100">
                  <b-form-select
                      id="ablaufselect"
                      size="sm"
                      class="w-100"
                      v-model="form.ablauf"
                      :options="ablaufoptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Ablauf --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>

              </b-col>
              <b-col md="3">
                <div class="form-group w-100">
                  <b-form-select
                      id="auftragstatusselect"
                      size="sm"
                      class="w-100"
                      v-model="form.auftragstatus"
                      :options="auftragstatusoptions">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Status --</b-form-select-option>
                    </template>
                  </b-form-select>
                </div>
              </b-col>
            </b-row>
          </b-collapse>

        </b-col>

        <b-collapse id="mobile-sort" class="pt-3" v-model="sortToggle">
          <b-row>
            <b-col class="text-left">
              <ul>
                <li v-on:click="onSort('AUFTRAG_NR');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Auftrag
                </li>
                <li v-on:click="onSort('AUFTRAG_TYPE');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Art
                </li>
                <li v-on:click="onSort('NOMINALE');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Nominale
                </li>
                <li v-on:click="onSort('LIMIT');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Limit
                </li>
                <li v-on:click="onSort('TEILAUSFUEHRBAR');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> T/A
                </li>
                <li v-on:click="onSort('ABLAUF');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Ablauf
                </li>
                <li v-on:click="onSort('AUFTRAG_STATUS');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Status
                </li>
                <li v-on:click="onSort('FONDS');toggleSortFilterBar()">
                  <i class="fa fa-sort-alt"></i> Fonds
                </li>
              </ul>
            </b-col>
          </b-row>
        </b-collapse>

        <b-table id="auftragslistetable" ref="auftragslistetable" class="mobileTable pt-3"
            bordered
            stacked
            responsive="xl" 
            :isBusy.sync="isBusy"
            :items="dataProvider"
            :fields="fields"
            :per-page="perPage"
            :current-page="currentPage"
            :key="submitCount"
            head-variant="dark"
        >

          <template #cell(auftragsnr)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  <strong>
                    Auftrag
                  </strong>
                </td>
                <td class="label2">
                  <strong>
                    <router-link :to="{name: 'auftragsdetails', params: {client: client, id: data.item.id}}"><span v-if="data.item.makler === 'D'">{{ data.item.makler }}</span>{{ data.item.auftragNr }}</router-link>
                  </strong>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(auftragsart)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Art
                </td>
                <td class="label2">
                  {{ data.item.auftragsart }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(nominale)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Nominale
                </td>
                <td class="label2">
                  {{ formatPrice( data.item.nominale.betrag ) }} {{ data.item.nominale.waehrung }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(limit)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Limit
                </td>
                <td class="label2">
                  {{ formatPercent( data.item.limit ) }}%
                </td>
              </tr>
            </table>
          </template>

          <template #cell(teilausfuehrbar)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  T/A
                </td>
                <td class="label2">
                  <div v-if="data.item.teilausfuehrbar">
                    <i class="fas fa-check-circle"></i>
                  </div>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(end)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Ablauf
                </td>
                <td class="label2">
                  {{ reformatDate( data.item.ablauf ) }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(status)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Status
                </td>
                <td class="label2">
                  {{ data.item.auftragsstatus }}
                </td>
              </tr>
            </table>
          </template>

          <template #cell(fonds)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  Fonds
                </td>
                <td class="label2">
                  <router-link :to="{name: 'fondsdetails', params: {client: client, id: data.item.trancheId}}">
                    {{ data.item.anbietername }} - {{ fondsNameWithZusatz( data.item ) }}
                  </router-link>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(actions)="data">
            <table class="innerTable">
              <tr>
                <td class="text-left label1">
                  &nbsp;
                </td>
                <td class="label2">
                  <div v-if="!data.item.canCreateNote"></div>
                  <div v-else-if="data.item.hasNote">
                    <a @click="addNote(data.item.id, data.item.hasNote)" class="pt-2 pl-1 watchLink">
                      <i class="fas fa-clipboard" title="Notiz bearbeiten"></i>
                    </a>
                  </div>
                  <div v-else>
                    <a @click="addNote(data.item.id, data.item.hasNote)" class="pt-2 pl-1 watchLink">
                      <i class="fas fa-notes-medical" title="Notiz erstellen"></i>
                    </a>
                  </div>
                </td>
              </tr>
            </table>
          </template>

          <template #cell(empty)="">
            <table class="innerTable">
              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </template>
          
          <template #table-busy>
            <div class="text-center my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Die Daten werden geladen...</strong>
            </div>
          </template>
        </b-table>

        <b-pagination
            id="pagination"
            v-model="currentPage"
            :total-rows="auftragListCount"
            :per-page="perPage"
            aria-controls="auftragslistetable"
            align="right"
            v-if="this.auftragListCount > perPage"
        ></b-pagination>

        <NoteDetails v-bind:objectId="objectId" v-bind:objectType="'AUFTRAG'" @successReload="forceRerender" v-bind:hasNote="hasNote" v-show="false"></NoteDetails>

      </div>
    </div>
    
  </div>
</template>

<script>
import priceMixin from "@/mixins/formatPrice";
import percentMixin from "@/mixins/formatPercent";
import dateMixin from "@/mixins/formatDate";
import NoteDetails from "@/components/notes/NoteDetails"
import { mapGetters } from "vuex";
import axios from "axios";

export default {
  name: "AuftragListTable",
  components: {NoteDetails},
  data: () => ({
    /* table controls */
    isBusy: false,
    currentPage: 1,
    perPage: 25,
    auftragListCount: 0,
    submitCount: 0,
    status: null,
    
    objectId: null,
    objectType: '',
    hasNote: false,
    form: {
      auftragnr: '',
      searchcombinedname: '',
      auftragsart: null,
      vermittelbar: null,
      ablauf: null,
      auftragstatus: null,
      sortproperty: 'AUFTRAG_NR',
      sortdirection: 'desc',
    },
    sortFilterBar: true,
    sortToggle: false,
    filterToggle: false,
    auftragartoptions: [
      { value: 'ALLE', text: 'Alle' },
      { value: 'ALLE_AUFTRAEGE', text: 'Alle Aufträge' },
      { value: 'KAUF', text: '- Kauf' },
      { value: 'VERKAUF', text: '- Verkauf' },
      { value: 'ALLE_DIREKTAUFTRAEGE', text: 'Alle Direktaufträge' },
      { value: 'DIREKTKAUF', text: '- Direkt-Kauf' },
      { value: 'DIREKTVERKAUF', text: '- Direkt-Verkauf' },
      { value: 'ALLE_INTERESSEN', text: 'Alle Interessen' },
    ],
    vermittelbaroptions: [
      { value: 'ALLE', text: 'alle' },
      { value: 'VERMITTELBAR', text: 'vermittelbar' },
      { value: 'UNVERMITTELT', text: '- unvermittelt' },
      { value: 'TEILVERMITTELT', text: '- teilvermittelt' },
      { value: 'VERMITTELT', text: 'vermittelt' },
    ],
    ablaufoptions: [
      { value: 'ALLE', text: 'alle' },
      { value: '30DAYS', text: 'Laufende mit Ablauf innerhalb 30 Tagen' },
      { value: 'BEENDET', text: 'Abgelaufen' },
    ],
    auftragstatusoptions: [
      { value: 'ALLE', text: 'Alle' },
      { value: 'NICHT_FREIGEGEBEN', text: 'Nicht freigegeben' },
      { value: 'ANGELEGT', text: 'Angelegt' },
      { value: 'ERSTELLT', text: 'Erstellt' },
      { value: 'BEIM_TH', text: 'Beim Treuhänder' },
      { value: 'FREIGEGEBEN', text: 'Freigegeben' },
      { value: 'BEENDET', text: 'Beendet' },
      { value: 'GELOESCHT', text: 'Gelöscht' },
    ],
    sortoptions: [
      { key: 'AUFTRAG_NR', name: 'AUFTRAG_NR', sortdir: 'desc', displayName: 'Auftrag' },
      { key: 'AUFTRAG_TYPE', name: 'AUFTRAG_TYPE', sortdir: 'none', displayName: 'Art' },
      { key: 'AUFTRAG_STATUS', name: 'AUFTRAG_STATUS', sortdir: 'none', displayName: 'Status' },        
      { key: 'NOMINALE', name: 'NOMINALE', sortdir: 'none', displayName: 'Nominale' },
      { key: 'LIMIT', name: 'LIMIT', sortdir: 'none', displayName: 'Limit' },
      { key: 'TEILAUSFUEHRBAR', name: 'TEILAUSFUEHRBAR', sortdir: 'none', displayName: 'T/A' },
      { key: 'BEGIN', name: 'BEGIN', sortdir: 'none', displayName: 'Beginn' },
      { key: 'ABLAUF', name: 'ABLAUF', sortdir: 'none', displayName: 'Ablauf' },
      { key: 'FONDS', name: 'FONDS', sortdir: 'none', displayName: 'Fonds' },
    ],
  }),
  computed: {
    ...mapGetters('auftragsliste', ['getAuftragslistData','isAuftragslistWaiting','getAuftragslistCount']),
    fields() {
      return [
        { key: 'auftragsnr', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'auftragsart', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'nominale', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'limit', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'teilausfuehrbar', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'end', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'status', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'fonds', label: '', thClass: ["text-left", "hidden-header"] /*, tdClass: ["col-20-percent"] */ },
        { key: 'actions', label: '', thClass: ["text-left", "hidden-header"] },
        { key: 'empty', label: '', tdClass: ["mobileRow"] }
      ] 
    },
    client() {
      return this.$store.getters['clientConfig/client']
    },
  },
  methods: {
    resetSort() {
      this.form.sortproperty = 'AUFTRAG_NR';
      this.form.sortdirection = 'desc';
      this.onSort( this.form.sortproperty, this.form.sortdirection );
    },
    currentSortDirIsDesc() {
      var currentSortDir = 'desc';
      for( var sortopt of this.sortoptions ) {
        if( sortopt.sortdir !== 'none' ) {
          currentSortDir = sortopt.sortdir;
        }
      }
      return currentSortDir === 'desc';
    },
    addDays( days ) {
      Date.prototype.addDays = function(days) {
        var date = new Date(this.valueOf());
        date.setDate(date.getDate() + days);
        return date;
      }
      return new Date().addDays( days );
    },
    formatDate( date ) {
      let d = date,
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [day, month, year].join('.');
    },
    resetFilter( fieldKey ) {
      if ( 'searchcombinedname' === fieldKey ) {
        this.form.searchcombinedname = null;
      }
      else if ( 'auftragnr' === fieldKey ) {
        this.form.auftragnr = null;
      }
      else if ( 'auftragsart' === fieldKey ) {
        this.form.auftragsart = null;
      }
      else if ( 'vermittelbar' === fieldKey ) {
        this.form.vermittelbar = null;
      }
      else if ( 'auftragstatus' === fieldKey ) {
        this.form.auftragstatus = null;
      }
      else if ( 'ablauf' === fieldKey ) {
        this.form.ablauf = null;
      }
      else {
        console.log( 'Unknown form field key ' + fieldKey );
      }
      let event = null;
      this.onSubmit( event );
    },
    anyFilter() {
      return this.form.searchcombinedname ||
          this.form.ablauf ||
          this.form.auftragstatus ||
          this.form.vermittelbar ||
          this.form.auftragsart ||
          this.form.auftragnr;
    },
    mapAuftragsart() {
      if (!this.form.auftragsart) return '';
      for( var opt of this.auftragartoptions ) {
        if (opt.value === this.form.auftragsart ) { 
          let res = opt.text;
          if ( res.includes('- ') ) {
            res = res.substring( 2 );
          }
          if ( this.form.auftragsart === 'ALLE' ) {
            return 'Auftragsart: ' + res;
          }
          return res; 
        }
      }
      return '';  
    },
    mapVermittelbar() {
      if (!this.form.vermittelbar) return '';
      for( var opt of this.vermittelbaroptions ) {
        if (opt.value === this.form.vermittelbar ) {
          let res = opt.text;
          if ( res.includes('- ') ) {
            res = res.substring( 2 );
          }
          if ( this.form.vermittelbar === 'ALLE' ) {
            return 'Vermittelbarkeit: ' + res;
          }
          return res;
        }
      }
      return '';
    },
    mapAblauf() {
      if (!this.form.ablauf) return '';
      for( var opt of this.ablaufoptions ) {
        if (opt.value === this.form.ablauf ) {
          if ( this.form.ablauf === 'ALLE' ) {
            return 'Ablauf: ' + opt.text;
          }
          return opt.text 
        }
      }
      return '';
    },
    mapAuftragstatus() {
      if (!this.form.auftragstatus) return '';
      for( var opt of this.auftragstatusoptions ) {
        if (opt.value === this.form.auftragstatus ) {
          let res = opt.text;
          if ( res.includes('- ') ) {
            res = res.substring( 2 );
          }
          if ( this.form.auftragstatus === 'ALLE' ) {
            return 'Auftragstatus: ' + res;
          }
          return res;
        }
      }
      return '';
    },
    hasAuftragslistError() {
      var status = this.auftragslistError();
      return !(status === undefined || status === null || status === '' || status === "" )
    },
    auftragslistError() {
      return this.status;
    },
    forceRerender() {
      this.submitCount++;
    },
    redirectToAuftrag() {
      const reqParams = {
        client: this.$route?.params?.client,
        auftragNr: this.form.auftragnr
      }
      this.$store.dispatch('auftragsliste/redirectToAuftrag',reqParams);
    },
    dataProvider( ctx, callback ) {
      this.isBusy = true;
      let endBeforeKey = this.form.ablauf ? this.form.ablauf : '';
      let endBeforeRequestValue = '';
      if ( endBeforeKey === '30DAYS') {
        endBeforeRequestValue = this.formatDate( this.addDays( 30 ) );  
      }
      else if ( endBeforeKey === 'BEENDET') {
        endBeforeRequestValue = this.formatDate( this.addDays( -1 ) );
      }

      let token = this.$store.getters['auth/getToken']
      if(token === '' || token === undefined){
        throw new Error('Missing Token!')
      }
      
      const reqParams = {
        endBefore:          endBeforeRequestValue,
        fondsname:          this.form.searchcombinedname,
        auftragArt:         this.form.auftragsart? this.form.auftragsart : 'ALLE',
        auftragStatus:      this.form.auftragstatus? this.form.auftragstatus : 'ALLE',
        vermittelbarkeit:   this.form.vermittelbar? this.form.vermittelbar : 'ALLE',
        page:               ctx.currentPage - 1,
        size:               ctx.perPage,
        sort:               this.form.sortproperty ? this.form.sortproperty + ',' + this.form.sortdirection : '',
      };
      var config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        crossdomain: true,
        params: reqParams,
      };
      axios.get(this.$store.getters.getAllEnvis.API_USER_AUFTRAEGE, config)
          .then(response => {
            const items = response.data.content;
            callback( items );
            this.auftragListCount = response.data.page.total_elements;
            this.isBusy = false;
          }, error => {
            this.error = error;
            callback( [] );
          } )
          .catch(error => {
            console.log(error);
            this.isBusy = false;
            this.error = error;
            callback( [] );
          })
    },
    fondsNameWithZusatz( dataItem ) {
      if ( dataItem.fondsnamezusatz && dataItem.fondsnamezusatz !== '' ) {
        return dataItem.fondsname + ' - ' + dataItem.fondsnamezusatz; 
      }
      return dataItem.fondsname
    },
    onSubmit( event ) {
      if ( event ) { event.preventDefault() }
      if ( this.form.auftragnr && this.form.auftragnr !== '' ) {
        this.redirectToAuftrag();
      }
      else {
        this.currentPage = 1;
        this.forceRerender();
      }
    },
    getSortObject( sortkey ) {
      for( var sortopt of this.sortoptions ) {
        if( sortopt.key === sortkey || sortopt.name === sortkey ) {
          return sortopt;
        }
      }  
      return [];
    },
    onSort: function( sortpropkey ) {
      var sortopt = this.getSortObject( sortpropkey );
      // Get current sortdir and then toggle
      if ( 'none' === sortopt.sortdir || 'desc' === sortopt.sortdir ) {
        sortopt.sortdir = 'asc';
      }
      else if ( 'asc' === sortopt.sortdir ) {
        sortopt.sortdir = 'desc';
      }

      var newSort = sortopt.sortdir;

      // reset all others
      for( var sortopt2 of this.sortoptions ) {
        if( sortopt2.key === sortpropkey ) {
          // do nothing;
        }
        else {
          sortopt2.sortdir = 'none';
        }
      }

      this.form.sortproperty = sortpropkey;
      this.form.sortdirection = newSort;

      let event = null;
      this.onSubmit( event );
    },
    hasToken() {
      return this.$store.getters["auth/hasToken"];
    },
    addNote(id, hasNote) {
      this.objectId = id;
      this.hasNote = hasNote;
      this.noteData = this.$store.getters["notes/getNoteData"];
      this.$bvModal.show( 'noteDetails' );
    },
    toggleSortFilterBar() {
      if(!this.sortFilterBar) {
        this.sortFilterBar = true;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }
    },
    toggleSortLayer() {
      if(this.sortToggle) {
        this.sortToggle = false;
        return;
      }

      if(this.filterToggle) {
        this.filterToggle = false;
      }

      if(this.sortFilterBar) {
        this.sortFilterBar = false;
      }

      this.sortToggle = true;
    },
    toggleFilterLayer() {
      if(this.filterToggle) {
        this.filterToggle = false;
        return;
      }

      if(this.sortToggle) {
        this.sortToggle = false;
      }

      this.filterToggle = true;
    }
  },
  mixins: [priceMixin,percentMixin,dateMixin],
  mounted() {
    this.$nextTick(function () {
      if ( !this.isBusy ) {
        this.submitCount++;
      }
    });
  },
  // Reminder - eventlisteners must be deleted in beforeDestroy if created before
}
</script>

<style type="scss" scoped>
</style>